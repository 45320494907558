import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { DasoAuthService } from '../services/daso-auth.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountType } from 'src/app/enums/account-type.enum';

@Injectable({
  providedIn: 'root'
})
export class DasoLoggedAuthGuard implements CanActivate {
  constructor(
    private authService: DasoAuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const id = parseInt(route.paramMap.get('dasoId'), 10);
    return this.authService.isAuthenticated({ prefix: AccountType.Daso, id });
  }
}
