import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpClient, HttpEvent } from '@angular/common/http';
import { StorageService } from './storage.service';
import { Platform } from '@ionic/angular';
import { AccountType } from '../enums/account-type.enum';
import { UrlInfo } from '../_models/UrlInfoInterface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private types = [AccountType.Main, AccountType.Lk, AccountType.Iviz, AccountType.Doorphone];

  public isLoggedIn: Array<BehaviorSubject<boolean>> = [];

  public token: Array<string> = [];

  public credentials = null;

  public redirectUrl = '';

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private router: Router,
    private platform: Platform,
  ) {
    this.types.forEach(type => {
      this.isLoggedIn.push(new BehaviorSubject(false));
      this.token.push('');
    });
  }

  public loadAuth() {
    this.platform.ready().then(() => {
      this.types.forEach(type => {
        const index = this.types.indexOf(type);
        this.storage.get(type + '_token').then(token => {
          if (token) {
            this.token[index] = token;
            this.isLoggedIn[index].next(true);
          } else {
            this.token[index] = '';
            this.isLoggedIn[index].next(false);
          }
        });
        this.storage.get(type + '_credentials').then(credentials => {
          if (credentials) {
            this.credentials = credentials;
          }
        });
      });
    });
  }

  public getAuthStatus(type: UrlInfo) {
    return this.isLoggedIn[this.types.indexOf(type.prefix)];
  }

  public getAuthHeader(type: UrlInfo) {
    const index = this.types.indexOf(type.prefix);
    return this.token[index] ? 'Bearer ' + this.token[index] : '';
  }

  public setToken(token: string, type: UrlInfo) {
    const index = this.types.indexOf(type.prefix);
    this.token[index] = token.replace('Bearer ', '');

    this.storage.set(type.prefix + '_token', this.token[index]);
  }

  public getToken(type: UrlInfo) {
    return this.token[this.types.indexOf(type.prefix)];
  }

  public login(token: string, type: UrlInfo) {
    this.setToken(token, type);
    this.isLoggedIn[this.types.indexOf(type.prefix)].next(true);
  }

  public logout(type: UrlInfo) {
    const index = this.types.indexOf(type.prefix);
    this.isLoggedIn[index].next(false);
    this.token[index] = '';

    this.storage.set(type.prefix + '_token', '');
  }

  public isAuthenticated(type: UrlInfo) {
    return this.isLoggedIn[this.types.indexOf(type.prefix)].value;
  }

  public refreshToken(type: UrlInfo) {
    return new Observable<HttpEvent<any>>(subscriber => {
      let request;
      // login because refresh not work on some types
      switch (type.prefix) {
        case AccountType.Iviz:
          request = this.http.post(`${type.prefix}://login`, this.credentials);
          break;
        case AccountType.Doorphone:
          request = this.http.post('main://authIntercom', {}, { observe: 'response' });
          break;
        default:
          request = this.http.get(type.prefix + '://auth/refresh', { observe: 'response' });
      }

      request.subscribe(
        (response: HttpEvent<any>) => {
          subscriber.next(response);
          subscriber.complete();
        },
        error => {
          subscriber.error(error);
          subscriber.complete();
        },
      );
    });
  }
}
