export enum PageType {
  Player = 'player',
  ObjectPlayer = 'object-player',
  FavoritesPlayer = 'favorites-player',
  Archive = 'archive',
  /**
   * Camera archive from the archive playlist.
   */  
  TimelinePlaylist = 'timeline-playlist',
  /**
   * Camera archive from the object playlist.
   */ 
  TimelineObjectPlaylist = 'timeline-object-playlist',

  /**
   * Real time camera archive.
   */
  TimelineCamera = 'timeline-camera',
  TimelineFavorites = 'timeline-favorites',
}
