import { registerPlugin } from '@capacitor/core';

export interface SipPluginAuthStatus {
  result: string,
};

export interface SipPluginCallStatus {
  result: string,
  info?: string,
}

export type SipPluginCallback = (
  data: SipPluginAuthStatus | SipPluginCallStatus | null,
  error?: any,
) => void;

export interface SipPlugin {
  login(options: any, callback: SipPluginCallback): Promise<void>;
  logout(): Promise<void>;
  listenCall(options: any, callback: SipPluginCallback): Promise<void>;
  setForeground(options: { status: string }): Promise<void>;
  setGrainDb(options: { micDb: string, playbackDb: string }): Promise<void>;
  openUrl(options: { url: string }): Promise<void>;
}

const Sip = registerPlugin<SipPlugin>('Sip');

export default Sip;