import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { LkUserService } from '../lk/services/user.service';
import { EvoUserInfo } from '../_models/EvoUserInfoInterface';
import { EvoAccountInfo } from '../_models/EvoAccountInfoInterface';
import { Preferences } from '@capacitor/preferences';
import Livetex, { LivetexPlugAnswer } from '../plugins/Livetex';
import { StorageKey } from '../enums/storage-key.enum';
import { AccountType } from '../enums/account-type.enum';

@Injectable({
  providedIn: 'root'
})
export class LivetexService {

  public newMessages: BehaviorSubject<boolean>;
  public name: string;

  constructor(
    private authService: AuthService,
    private userService: LkUserService,
  ) {
    this.newMessages = new BehaviorSubject(false);
  }

  init(): void {
    this.refresh();

    Livetex.initCore();

    this.initCallback();
  }

  refresh(): void {
    Preferences.get({ key: StorageKey.ChatNewMessages}).then(
      ({ value: flag }) => {       
        this.newMessages.next(flag === '1' ? true : false);
      }
    );

    Preferences.get({ key: StorageKey.ChatClientName}).then(
      ({ value: name }) => {  
        this.name = name;
      }
    );
  }

  initCallback(): void {
    Livetex.setCallback(
      {},
      (data: LivetexPlugAnswer) => {
        this.setFlag(data?.answer === 'receive');
      }
    );
  }

  /**
   * Opens a chat. Gets data from lk evo, store or modal window. Returns the status whether it was possible to open the chat
   * @param name - modal data
   */
  open(name: string = null): boolean {
    if (this.authService.isAuthenticated({ prefix: AccountType.Lk })) {
      this.userService.getEvoUser().subscribe(
        (user: EvoUserInfo) => {
          this.userService.getEvoUserAccounts().subscribe(
            (accounts: Array<EvoAccountInfo>) => {
              const info = [];

              accounts.forEach((el) => {
                info.push(el.account_info.account_id);
              });

              this.setFlag(false);
              // takes from the data account_id and name
              Livetex.open({
                  name: user.user_name + (info.length ? '(' + info.join(', ') + ')' : ''),
                },
                (error: string) => {},
              );
            }
          );
        }
      );

      return true;
    } else if (name != null) {
      this.name = name;
      Preferences.set({
        key: StorageKey.ChatClientName,
        value: name
      });

      this.setFlag(false);
      Livetex.open({
          name,
        },
        (error: string) => {}
      );

      return true;
    } else if (this.name) {
      this.setFlag(false);
      Livetex.open({
          name: this.name,
        },
        (error: string) => {}
      );

      return true;
    }

    return false;
  }

  destroy(): void {
    Livetex.destroy();
  }

  private setFlag(flag: boolean): void {
    this.newMessages.next(flag);
    Preferences.set({
      key: StorageKey.ChatNewMessages,
      value: flag ? '1' : '0'
    });
  }
}
