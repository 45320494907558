import { Injectable } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import Sip from '../plugins/Sip';

export type SipRegisterCallback = (result: Boolean) => void;

@Injectable({
  providedIn: 'root',
})
export class SipService extends BaseComponent {

  public register(callback?: SipRegisterCallback) {
    Sip.login({}, (data, error) => {
      if (callback) {
        callback(error ? false : true)
      }
    })
  }

  enableForeground() {
    Sip.setForeground({ status: 'true' });
  }

  disableForeground() {
    Sip.setForeground({ status: 'false' });
  }

  listen() {

  }

  /**
   * logging out from sip account
   */
  public logout() {
    Sip.logout(); 
  }

}
