import { Injectable } from '@angular/core';
import { Device, DeviceId } from '@capacitor/device';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {

	public uuid = '';
	public name = '';
	public model = '';
	public platform = 'web';
	public version = '';
	public manufacturer = '';

	public async init() {
		const deviceId: DeviceId = await Device.getId();
		const info = await Device.getInfo()

		this.uuid = deviceId?.identifier;

		this.name = info.name ?? '';
		this.model = info.model ?? '';
		this.platform = info.platform ?? '';
		this.version = info.osVersion ?? '';
		this.manufacturer = info.manufacturer ?? '';
	}
}