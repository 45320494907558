import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AccountType } from 'src/app/enums/account-type.enum';

interface LoginResponse {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class DoorphoneUserService {
  constructor(private http: HttpClient, private authService: AuthService, public alertController: AlertController) {}

  async errorAlert(message) {
    const alert = await this.alertController.create({
      header: 'Ошибка',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  public logout() {
    this.authService.logout({ prefix: AccountType.Doorphone });
  }

  /**
   * checks if authorization is needed in intercoms and authorizes
   * @returns - authorization flag
   */
  public login(): Observable<boolean> {
    return new Observable(subscriber => {
      if (this.authService.isAuthenticated({ prefix: AccountType.Doorphone })) {
        subscriber.next(true);
        subscriber.complete();
      } else if (this.authService.isAuthenticated({ prefix: AccountType.Main })) {
        this.http.post('main://authIntercom', {}).subscribe(
          (res: LoginResponse) => {
            if (res.token) {
              this.authService.login(res.token, { prefix: AccountType.Doorphone });
              subscriber.next(true);
            } else {
              subscriber.next(false);
            }

            subscriber.complete();
          },
          (error: HttpErrorResponse) => {
            subscriber.next(false);
            subscriber.complete();
          },
        );
      } else {
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }
}
