import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { AlertController } from '@ionic/angular';
import { SipService } from '../../services/sip.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';
import { takeUntil } from 'rxjs/operators';
import { DoorphoneUserService } from './doorphone-user.service';
import { DoorphoneService } from '../../services/doorphone.service';
import { EvoAccountInfo } from '../../_models/EvoAccountInfoInterface';
import { EvoUserInfo } from '../../_models/EvoUserInfoInterface';
import { LoginResponseData } from '../../_models/LoginResponseDataLKInterface';
import { UserInfo } from 'src/app/_models/UserInfoInterface';
import { ErrorEvoAccountResponse, SuccessEvoAccountResponse } from '../../_models/EvoAccountResponseInterface';
import { SendSmsAnswer } from '../../_models/SendSmsAnswerInterface';
import { PaymentsList } from 'src/app/_models/PaymentInfoInterface';
import { BaseComponent } from 'src/app/base/base.component';
import { AccountType } from 'src/app/enums/account-type.enum';
import { EntryDevicesCountService } from 'src/app/services/intercom-count.service';

export type ResultCallback = (result: Boolean) => void;

@Injectable({
  providedIn: 'root',
})
export class LkUserService extends BaseComponent {
  public userInfo: UserInfo;

  private evoUser: EvoUserInfo;
  private evoObservable;

  private evoAccounts: Array<EvoAccountInfo>;
  private evoAccountsObservable;

  private intercomLoading = false;

  public numberAttempts = 0;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public alertController: AlertController,
    private doorphoneUserService: DoorphoneUserService,
    private sipService: SipService,
    private doorphoneService: DoorphoneService,
    private entryDevicesCountService: EntryDevicesCountService,
  ) {
    super();
  }

  async errorAlert(message): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Ошибка',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  /**
   * log out of the lk account if the status is 200, 401 or 0:
   * a logout request is made
   * the token value is deleted from the storage and from the authService
   */
  public logout(): void {
    this.evoUser = null;

    this.http
      .post('lk://auth/logout', {})
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(
        result => {
          this.authService.logout({ prefix: AccountType.Lk });
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 0) {
            this.authService.logout({ prefix: AccountType.Lk });
          }
        },
      );
  }

  /**
   * logging out of the doorphone account
   */
  public logoutMain(): void {
    this.http
      .post('main://auth/logout', {})
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(
        result => {
          this.authService.logout({ prefix: AccountType.Main });
          this.authService.logout({ prefix: AccountType.Doorphone });

          this.sipService.logout();
          this.entryDevicesCountService.setAvailableIntercomId(null);
          this.entryDevicesCountService.setEntryDevicesCount(null);
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 0) {
            this.authService.logout({ prefix: AccountType.Main });
            this.authService.logout({ prefix: AccountType.Doorphone });

            this.sipService.logout();
            this.entryDevicesCountService.setAvailableIntercomId(null);
            this.entryDevicesCountService.setEntryDevicesCount(null);
          }
        },
      );
  }

  public sendSms(phone: string): Observable<SendSmsAnswer> {
    return this.http.post<SendSmsAnswer>('main://auth/phoneLogin', { phone });
  }

  public requestCall(phone: string): Observable<SendSmsAnswer> {
    return this.http.post<SendSmsAnswer>('main://verify/send-code', { phone });
  }

  public loginByPhone(phone: string, code: string, isSmsCode = false) {
    this.evoUser = null;

    return new Observable(subscriber => {
      this.http
        .post(`main://${isSmsCode ? 'auth/phoneLogin' : 'verify/login-by-code' }`, { phone, code })
        .pipe(takeUntil(this.ngOnDestroy$))
        .subscribe(
          (res: LoginResponseData) => {
            if (!isSmsCode) {
              this.numberAttempts++;
            }
            if (res?.access_token) {
              this.entryDevicesCountService.setEntryDevicesCount(res?.doorphone_data?.doorphone_count + res?.doorphone_data?.barrier_count);
              this.entryDevicesCountService.setAvailableIntercomId(res?.doorphone_data?.doorphone_id);
          
              this.authService.login(res.access_token, { prefix: AccountType.Main });
          
              if (res?.lk) {
                this.authService.login(res.access_token, { prefix: AccountType.Lk });
              }
          
              this.doorphoneService.setPaidWarning(res.paid_warning);
            } else {
              subscriber.next('Неправильный код.');
              subscriber.complete();
            }

            subscriber.next('');
            subscriber.complete();
          },
          (error: HttpErrorResponse) => {
            if (!isSmsCode) {
              this.numberAttempts++;
            }
            let errMess = 'Сервис временно не доступен, попробуйте позже.';
            switch (error?.status) {
              case 400:
                switch (error.error.message) {
                  case 'Operations is blocked':
                    errMess =
                      'Попытки для текущего звонка закончились. По истечении таймаута нажмите "Повторный звонок"';
                    break;
                  case 'Invalid code':
                    errMess = 'Неправильный код.';
                    break;
                  case 'Operations not found':
                    errMess = 'Не найдена операция подтверждения кода.';
                    break;
                }
                break;
              case 401:
                errMess = 'Неправильный код.';
                break;
              case 423:
                errMess = 'Сервис временно не доступен, попробуйте позже.';
                break;
              case 422:
                if (error.error.message === 'The given data was invalid.') {
                  errMess = 'Неправильный код.';
                }
                break;
            }

            subscriber.next(errMess);
            subscriber.complete();
          },
        );
    });
  }

  public login(login: String, password: String, callback?): void {
    this.evoUser = null;

    this.http
      .post('lk://auth/login', {
        login,
        password,
      })
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(
        (res: LoginResponseData) => {
          if (res.access_token) {
            this.authService.login(res.access_token, { prefix: AccountType.Lk });

            if (callback) {
              callback.call();
            }
          } else {
            this.errorAlert('Неправильный логин или пароль.');
          }
        },
        // TODO: неправильно вызывать alert controller из сервиса
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.errorAlert('Неправильный логин или пароль.');
          } else {
            this.errorAlert('Не удалось выполнить запрос. Пожалуйста, попробуйте позже');
          }
        },
      );
  }

  public addEvoAccount(login: String, password: String, callback: ResultCallback = null): void {
    this.http
      .post('lk://loginEvo', {
        login,
        password,
      })
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(
        (res: SuccessEvoAccountResponse) => {
          this.userInfo = res.user;
          if (callback) {
            callback(true);
          }
        },
        // TODO: неправильно вызывать alert controller из сервиса
        (error: ErrorEvoAccountResponse) => {
          if ('error' in error) {
            this.errorAlert(error.error.error);
          } else {
            this.errorAlert('Не удалось выполнить запрос. Пожалуйста, попробуйте позже');
          }

          if (callback) {
            callback(false);
          }
        },
      );
  }

  public authIntercom(): void {
    this.authService.getAuthStatus({ prefix: AccountType.Doorphone }).pipe(takeUntil(this.ngOnDestroy$)).subscribe();
  }

  /**
   * checks authorization in intercoms, gets a list of data, writes sip settings to storage and initializes in sip service
   */
  public loadIntercomData(): void {
    if (!this.intercomLoading) {
      this.intercomLoading = true;
      this.doorphoneUserService
        .login()
        .pipe(takeUntil(this.ngOnDestroy$))
        .subscribe((auth: boolean) => {
          if (auth) {
            this.doorphoneService
              .getDoorphones()
              .pipe(takeUntil(this.ngOnDestroy$))
              .subscribe(intercoms => {
                this.intercomLoading = false;
              });
          } else {
            this.intercomLoading = false;
          }
        });
    }
  }

  getAccountPayments(accountId: number): Observable<PaymentsList> {
    return this.http.get<PaymentsList>(`lk://paymentsEvo/${accountId}`);
  }

  getEvoUser(reload: Boolean = false) {
    if (!reload && this.evoUser) {
      return Observable.of(this.evoUser);
    } else if (!reload && this.evoObservable) {
      return this.evoObservable;
    } else {
      this.evoObservable = this.http
        .get<EvoUserInfo>('lk2://userInfoEvo', {
          observe: 'response',
        })
        .map(response => {
          this.evoObservable = null;

          if (response.body) {
            this.evoUser = response.body;
          } else {
            this.evoUser = null;
          }

          return this.evoUser;
        })
        .share();

      return this.evoObservable;
    }
  }

  getEvoUserAccounts(reload: Boolean = false) {
    if (!reload && this.evoAccounts) {
      return Observable.of(this.evoAccounts);
    } else if (!reload && this.evoAccountsObservable) {
      return this.evoAccountsObservable;
    } else {
      this.evoAccountsObservable = this.http
        .get<Array<EvoAccountInfo>>('lk://get_user_info_accounts_services', {
          observe: 'response',
        })
        .map(response => {
          this.evoAccountsObservable = null;

          if (response.body) {
            this.evoAccounts = response.body;
          } else {
            this.evoAccounts = null;
          }

          return this.evoAccounts;
        })
        .share();

      return this.evoAccountsObservable;
    }
  }

  updateEmailEvo(phones, email, seconds) {
    return this.http.post('lk://updateContactEmail', {phones, email, seconds});
  }
}
