import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { StorageService } from '../../services/storage.service';
import { Platform } from '@ionic/angular';
import { StorageKey } from '../../enums/storage-key.enum';
import { UrlInfo } from 'src/app/_models/UrlInfoInterface';
import { AccountType } from 'src/app/enums/account-type.enum';

interface DasoAuth {
  id: number;
  isLoggedIn: BehaviorSubject<boolean>;
  tokens: DasoToken;
}

interface DasoToken {
  id: number;
  token: string;
  ivizToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class DasoAuthService {
  public data: Array<DasoAuth> = [];

  private subscribers: Array<number> = [];

  credentials = null;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private router: Router,
    private platform: Platform,
  ) {}

  public loadAuth(): void {
    this.platform.ready().then(() => {
      this.data = [];
      this.storage.get(StorageKey.DasoToken).then((data: Array<DasoToken>) => {
        if (data) {
          data.forEach((val: DasoToken) => {
            const object: DasoAuth = {
              id: val.id,
              isLoggedIn: new BehaviorSubject(val.token && val.token.length ? true : false),
              tokens: val,
            };

            this.data.push(object);
          });
        }
      });
    });
  }

  public subscribeAuth(id: number): void {
    if (!this.subscribers?.includes(id)) {
      const subscriber = this.getAuthStatus({ prefix: AccountType.Daso, id }).subscribe((state: boolean) => {
        if (!state && this.router.url.indexOf(`/daso-iviz/${id}/`) !== -1) {
          this.router.navigate(['/daso-info', id]);
          subscriber.unsubscribe();

          const index = this.subscribers.indexOf(id);
          if (index !== -1) {
            this.subscribers.splice(index, 1);
          }
        }
      });

      this.subscribers.push(id);
    }
  }

  public getAuthStatus(type: UrlInfo): BehaviorSubject<boolean> {
    if (type.id === 0 || isNaN(type.id)) {
      return null;
    }

    const el = this.getObject(type.id);
    return el.isLoggedIn;
  }

  private updateStorage(): void {
    const objects: Array<DasoToken> = [];

    this.data.forEach((el: DasoAuth) => {
      objects.push(el.tokens);
    });

    this.storage.set(StorageKey.DasoToken, objects);
  }

  private getObject(id: number): DasoAuth {
    const index = this.data.findIndex(daso => daso.id === id);

    if (index > -1) {
      return this.data[index];
    }

    const object: DasoAuth = {
      id,
      isLoggedIn: new BehaviorSubject(false),
      tokens: {
        id,
        token: '',
        ivizToken: '',
      },
    };

    this.data.push(object);

    return object;
  }

  public getAuthHeader(type: UrlInfo): string {
    const el = this.getObject(type.id);

    if (type.prefix === AccountType.Daso) {
      return el.tokens.token ? 'Bearer ' + el.tokens.token : '';
    } else {
      return el.tokens.ivizToken ? 'Bearer ' + el.tokens.ivizToken : '';
    }
  }

  public setToken(token: string, type: UrlInfo): void {
    const el = this.getObject(type.id);

    if (token == null) {
      token = '';
    }

    try {
      if (type.prefix === AccountType.Daso) {
        el.tokens.token = token.replace('Bearer ', '');
      } else {
        el.tokens.ivizToken = token.replace('Bearer ', '');
      }
    } catch (e) {
      console.log(e);
    }

    this.updateStorage();
  }

  public getToken(type: UrlInfo): string {
    const el = this.getObject(type.id);

    if (type.prefix === AccountType.Daso) {
      return el.tokens.token;
    } else {
      return el.tokens.ivizToken;
    }
  }

  public login(token: string, id: number): void {
    if (id && !isNaN(id) && id !== 0) {
      this.setToken(token, { prefix: AccountType.Daso, id });
      this.setToken('null', { prefix: AccountType.DasoIviz , id });

      const el = this.getObject(id);

      this.subscribeAuth(id);

      el.isLoggedIn.next(true);
    }
  }

  public logout(type: UrlInfo): void {
    const index = this.data.findIndex(daso => daso.id === type.id);
    if (index > -1) {
      this.data.splice(index, 1);
    }

    this.updateStorage();

    this.storage.remove(`daso-${type.id}_credentials`);
  }

  public isAuthenticated(type: UrlInfo): boolean {
    if (type.id === 0 || isNaN(type.id)) {
      return false;
    }

    const el = this.getObject(type.id);

    return el.isLoggedIn.value;
  }

  public refreshToken(type: UrlInfo): Observable<HttpEvent<any>> {
    this.storage.get(`daso-${type.id}_credentials`).then(credentials => {
      if (credentials) {
        this.credentials = credentials;
      }
    });

    return new Observable<HttpEvent<any>>(subscriber => {
      let request;
      switch (type.prefix) {
        case AccountType.Daso:
          request = this.http.post(`daso-${type.id}://auth/login/${type.id}`, this.credentials);
          break;
        default:
          request = this.http.get(`daso-${type.id}://iviz/${type.id}`, { observe: 'response' });
      }
      request.subscribe(
        (response: HttpEvent<any>) => {
          subscriber.next(response);
          subscriber.complete();
        },
        error => {
          subscriber.error(error);
          subscriber.complete();
        },
      );
    });
  }
}
