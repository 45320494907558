import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs/Observable';
import { CameraInterface, CamerasResponseInterface } from '../_models/CameraInterface';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';
import { StorageKey } from '../enums/storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class CamerasService {
  private cameras: Array<CameraInterface>;
  private observable;

  private host: String;

  public status: String = '';

  private _favorites = false;

  private _favoritesData: Array<number>;

  constructor(private http: HttpClient, private storage: StorageService) {
    this.host = environment.host;

    this.storage.get(StorageKey.FavoritesCamera).then((data: string) => {
      try {
        this._favoritesData = JSON.parse(data) as Array<number>;
      } catch (error) {
        console.log(error);
      }

      if (this._favoritesData == null) {
        this._favoritesData = [];
      }
    });
  }

  filterFavorites(flag: boolean) {
    this._favorites = flag;

    return this;
  }

  async addToFavorites(id: number) {
    this._favoritesData.push(id);
    this._favoritesData = this._favoritesData.filter((v, i, a) => a.indexOf(v) === i);

    await this.saveFavorites();
  }

  async deleteFromFavorites(id: number) {
    this._favoritesData = this._favoritesData.filter(e => e !== id);

    await this.saveFavorites();
  }

  protected async saveFavorites() {
    await this.storage.set(StorageKey.FavoritesCamera, JSON.stringify(this._favoritesData).toString());
  }

  get favorites() {
    return this._favoritesData;
  }

  isFavorite(id: number) {
    return this._favoritesData.indexOf(id) != -1;
  }

  getCameras(reload: boolean = false, city: number = 1) {
    if (!reload && this.cameras && !city) {
      return Observable.of(this.cameras);
    } else if (!reload && this.observable && !city) {
      return this.observable;
    } else {
      this.observable = this.http
        .get<CamerasResponseInterface>(`${this.host}/api/cameras`, {
          observe: 'response',
          params: { city },
        })
        .map(response => {
          this.observable = null;

          if (response.body) {
            this.cameras = response.body.cameras;
          } else {
            this.cameras = null;
          }

          return this.cameras;
        })
        .share();

      return this.observable;
    }
  }

  filter(filters: Array<String>, search: string, cameras: Array<CameraInterface>) {
    if (filters && filters.length) {
      cameras = cameras.filter(camera => filters.includes(camera.type));
    }

    if (search) {
      let _search = search.toLowerCase();

      cameras = cameras.filter(camera => camera.name.toLowerCase().search(_search) != -1);
    }

    if (this._favorites) {
      cameras = cameras.filter(camera => this._favoritesData.includes(camera.id as number));
    }

    return cameras;
  }
}
