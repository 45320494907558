import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AccountType } from '../enums/account-type.enum';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class IvizLoggedAuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(): boolean {
    return this.authService.isAuthenticated({ prefix: AccountType.Iviz });
  }
}
