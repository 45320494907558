import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { Platform } from '@ionic/angular';
import { StorageKey } from '../enums/storage-key.enum';
import { BaseComponent } from '../base/base.component';

@Injectable({
  providedIn: 'root',
})
export class EntityCountIvizStorageServise extends BaseComponent implements OnDestroy {
  public countEntity: number = null;
  public entityId:number = null;

  constructor(private storage: StorageService, private platform: Platform) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getEntityCountIviz() {
    return new Observable(subscriber => {
      this.platform.ready().then(() => {
        this.storage.get(StorageKey.EntityCountIviz).then(
          (count: number) => {
            subscriber.next(count);
            subscriber.complete();
          },
        );
      });
    }); 
  }

  getAvailableEntityId() {
    return new Observable(subscriber => {
      this.platform.ready().then(() => {
        this.storage.get(StorageKey.AvailableEntityId).then(
          (id: number) => {
            this.entityId = id;

            subscriber.next(id);
            subscriber.complete();
        },);
      });
    }); 
  }

  setEntityCountIviz(count: number) {
    this.countEntity = count;

    this.platform.ready().then(() => {
      this.storage.set(StorageKey.EntityCountIviz, count);
    });
  }

  setAvailableEntityId(id: number) {
    this.entityId = id;

    this.platform.ready().then(() => {
      this.storage.set(StorageKey.AvailableEntityId, id)
    });
  }
}
