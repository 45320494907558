import { registerPlugin } from '@capacitor/core';

export interface RedirectAnswer {
  redirect: boolean | undefined,
  url: boolean | undefined,
  params: boolean | undefined,
};

export interface RedirectPlugin {
  hasRedirect(options: any): Promise<RedirectAnswer>;
  setCallback(options: any, callback: (data: RedirectAnswer) => void): Promise<RedirectAnswer>;
}

const Redirect = registerPlugin<RedirectPlugin>('Redirect');

export default Redirect;