import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { Platform } from '@ionic/angular';
import { StorageKey } from '../enums/storage-key.enum';
import { BaseComponent } from '../base/base.component';

@Injectable({
  providedIn: 'root',
})
export class EntryDevicesCountService extends BaseComponent implements OnDestroy {
  public entryDevicesCount: number = null;
  public intercomId: number = null;
  public intercomIds: Array<number> = [];

  constructor(
    private storage: StorageService,
    private platform: Platform
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getEntryDevicesCount(): Observable<number> {
    return new Observable(subscriber => {
      this.platform.ready().then(() => {
        this.storage.get(StorageKey.IntercomCount).then((count: number) => {
          this.storage.get(StorageKey.IntercomPending).then(intercoms => {
            if (count === 1 && intercoms?.length === 0) {
              subscriber.next(count);
              subscriber.complete();
            } else {
              subscriber.next(null);
              subscriber.complete();
            }
          });
        });
      });
    });
  }

  getAvailableIntercomId(): Observable<number> {
    return new Observable(subscriber => {
      this.platform.ready().then(() => {
        this.storage.get(StorageKey.AvailableIntercomId).then((id: number) => {
          this.intercomId = id;
          subscriber.next(id);
          subscriber.complete();
        });
      });
    });
  }

  setEntryDevicesCount(count: number) {
    this.entryDevicesCount = count;
    this.platform.ready().then(() => {
      this.storage.set(StorageKey.IntercomCount, count);
    });
  }

  setAvailableIntercomId(id: number) {
    this.intercomId = id;
    this.platform.ready().then(() => {
      this.storage.set(StorageKey.AvailableIntercomId, id);
    });
  }

  setIntercomIds(ids: Array<number>) {
    this.intercomIds = [...ids];
    this.platform.ready().then(() => {
      this.storage.set(StorageKey.AvailableIntercomIds, ids);
    });
  }

  removeIntercom(id: number) {
    const ids = this.intercomIds.filter(val => val != id);

    this.setIntercomIds(ids);

    const count = this.entryDevicesCount - 1;
    this.setEntryDevicesCount(count);

    if (count === 1) {
      this.setAvailableIntercomId(ids[0]);
    }
  }
}
