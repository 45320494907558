import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SipService } from './services/sip.service';
import { LivetexService } from './services/livetex.service';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { DasoService } from './daso/services/daso.service';
import { DasoAuthService } from './daso/services/daso-auth.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { DeviceService } from './services/device.service';
import { AccountType } from './enums/account-type.enum';
import { Preferences } from '@capacitor/preferences';
import { EntityCountIvizStorageServise } from './services/iviz-entity-count.service';
import { PageType } from 'src/app/enums/page-type.enum';
import { EntryDevicesCountService } from './services/intercom-count.service';
import Redirect, { RedirectAnswer } from './plugins/RedirectPlugin';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { environment } from '../environments/environment';
import { StorageKey } from 'src/app/enums/storage-key.enum';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private sipService: SipService,
    private router: Router,
    private authService: AuthService,
    private livetexService: LivetexService,
    private navCtrl: NavController,
    private storage: StorageService,
    private dasoService: DasoService,
    private dasoAuthService: DasoAuthService,
    private device: DeviceService,
    private entityCountIvizStorageServise: EntityCountIvizStorageServise,
    private intercomCountService: EntryDevicesCountService,
    private zone: NgZone,
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const deepLink = this.platform.is('ios') ? 'evo://mobile' : environment.deepLink;

        Browser.close();

        const url = event.url.split("?");
        const slug = url[0].replace(deepLink, '');

        const searchParams = new URLSearchParams(url[1]);

        if (slug) {
          const params = {};
      
          for (let [key, value] of searchParams) {
            params[key] = value;
          }

          this.storage.set(StorageKey.DeepLinkParams, {...params, _time: new Date().getTime()}).then(() => {
            //this.router.navigate(['/'], { skipLocationChange: true, replaceUrl: false }).then(() => {
              this.router.navigate([slug], { replaceUrl: true })
            //});
          });
        }
      });
    });

    await this.platform.ready();

    await Preferences.configure({
      group: 'NativeStorage',
    });

    await this.storage.init();

    await this.device.init();

    this.entityCountIvizStorageServise.getAvailableEntityId();

    if (this.platform.is('ios')) {
      StatusBar.setStyle({ style: Style.Default });
    }

    setTimeout(() => SplashScreen.hide(), 2000);

    this.sipService.register();

    this.initAuth();

    this.dasoService.loadFavorites();

    this.livetexService.init();

    this.platform.resume.subscribe(() => {
      this.livetexService.initCallback();
      this.livetexService.refresh();

      this.setRedirectCallback();
    });

    const redirect = await Redirect.hasRedirect({});

    this.doRedirect(redirect);

    this.setRedirectCallback()
  }

  private setRedirectCallback() {
    Redirect.setCallback({}, (data: RedirectAnswer) => {
      this.doRedirect(data);

      this.setRedirectCallback();
    })
  }

  private doRedirect(redirect) {
    if (redirect.redirect) {
      setTimeout(() => this.navCtrl.navigateRoot(redirect.url), 1);
    }
  }

  private initAuth() {
    this.authService.loadAuth();
    this.dasoAuthService.loadAuth();

    this.authService.getAuthStatus({ prefix: AccountType.Iviz }).subscribe(state => {
      if (this.router.url.indexOf('/iviz') !== -1) {
        if (!state) {
          this.navCtrl.navigateRoot('/iviz-login');
        } else {
          let count = this.entityCountIvizStorageServise.countEntity;

          if (count && count === 1) {
            this.navCtrl.navigateRoot(['/iviz/player'], {
              state: {
                chanell: null,
                playerType: PageType.ObjectPlayer,
                section: AccountType.Iviz,
                entityId: this.entityCountIvizStorageServise.entityId
              },
            });
          } else {
            this.navCtrl.navigateRoot('/iviz/wall');
          }
        }
      }
    });

    this.authService.getAuthStatus({ prefix: AccountType.Lk }).subscribe(state => {
      if (this.router.url.indexOf('lk-') !== -1) {
        if (state) {
          this.navCtrl.navigateRoot('/lk-home');
        } else {
          this.navCtrl.navigateRoot('/lk-login');
        }
      }
    });

    this.authService.getAuthStatus({ prefix: AccountType.Main }).subscribe(state => {
      if (this.router.url.indexOf('ic-') !== -1) {
        if (state) {
          const intercomCount = this.intercomCountService.entryDevicesCount;
          if (intercomCount && intercomCount === 1) {
            this.navCtrl.navigateRoot([`/ic-home/doorphone/${this.intercomCountService.intercomId}`], { state: { whereToReturn : '/main' } });
          } else {
            this.navCtrl.navigateRoot('/ic-home/home');
          }
        } else {
          this.navCtrl.navigateRoot('/ic-login');
        }
      }
    });
  }
}
