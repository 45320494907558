import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: ``,
})
export abstract class BaseComponent implements OnDestroy {
  /**
   * Subject for watching on destroy hook
   */
  public ngOnDestroy$: Subject<void>;

  protected constructor() {
    this.ngOnDestroy$ = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
