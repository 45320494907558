import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AccountType } from '../enums/account-type.enum';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LkLoggedAuthGuard implements CanActivate {
  constructor(
    private authService: AuthService
  ) { }

  canActivate(): boolean {
    return this.authService.isAuthenticated({ prefix: AccountType.Lk }) ? true : false;
  }
}
