import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { DasoAuthService } from '../daso/services/daso-auth.service';
import { DeviceService } from '../services/device.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { AccountType } from '../enums/account-type.enum';
import { UrlInfo } from '../_models/UrlInfoInterface';

@Injectable({
  providedIn: 'root',
})
export class AuthHeaderInterceptor implements HttpInterceptor {
  protected _isRefreshing;

  constructor(
    private authService: AuthService,
    private dasoAuthService: DasoAuthService,
    private device: DeviceService,
    private platform: Platform,
  ) {
    this._isRefreshing = new Map();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const type: UrlInfo = this.parseUrl(request.url);

    if (type.prefix.toString() === 'http' || type.prefix.toString() === 'https') {
      return next.handle(request);
    }

    let nextRequest = request.clone();

    let headers = request.headers
      .set('Content-Type', 'application/json')
      .set('X-Device-UUID', this.device.uuid ? this.device.uuid : '0')
      .set('X-Device-Model', this.device.model ? this.device.model : '0')
      .set('X-Device-Platform', this.device.platform ? this.device.platform : this.getPlatform())
      .set('X-Device-Version', this.device.version ? this.device.version : '0');

    if (this.isRefreshing(type) || this.isAuthenticated(type)) {
      headers = headers.set('Authorization', this.getService(type).getAuthHeader(type));
    }

    nextRequest = request.clone({
      headers,
    });

    return next.handle(nextRequest).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && this.isAuthenticated(type)) {
          switch ((error as HttpErrorResponse).status) {
            case 400:
              if (type.prefix === AccountType.DasoIviz) {
                return this.isRefreshing(type) ? next.handle(request) : this.runRefresh(nextRequest, next, type);
              }

              return throwError(error);
            case 401:
              if (type.prefix !== AccountType.Iviz && (error as HttpErrorResponse)?.error?.error !== 'token_blocked') {
                return this.isRefreshing(type) ? next.handle(request) : this.runRefresh(nextRequest, next, type);
              } else {
                this.logout(error, type);
              }

              return throwError(error);
          }
        }

        return throwError(error);
      }),
    );
  }

  private logout(error: HttpErrorResponse, type: UrlInfo) {
    this.getService(type).logout(type);
    return throwError(error);
  }

  private runRefresh(request: HttpRequest<any>, next: HttpHandler, type: UrlInfo) {
    if (!this.isRefreshing(type) && this.needRefresh(type)) {
      this.setRefreshing(type, true);

      return this.getService(type)
        .refreshToken(type)
        .pipe(
          switchMap((response: HttpResponse<any>) => {
            let _response = response.hasOwnProperty('body') ? response.body : response;

            if (_response.hasOwnProperty('token')) {
              for (let i in _response) {
                if (i == 'token') {
                  let prevToken: string = this.getService(type).getToken(type);

                  this.setRefreshing(type, false);
                  this.getService(type).setToken(_response[i], type);

                  if (
                    type.prefix == AccountType.Lk &&
                    this.isAuthenticated({ prefix: AccountType.Main }) &&
                    this.getService(type).getToken({ prefix: AccountType.Main }) == prevToken
                  ) {
                    this.getService(type).setToken(_response[i], { prefix: AccountType.Main });
                  }

                  if (
                    type.prefix == AccountType.Main &&
                    this.isAuthenticated({ prefix: AccountType.Lk }) &&
                    this.getService(type).getToken({ prefix: AccountType.Lk }) == prevToken
                  ) {
                    this.getService(type).setToken(_response[i], { prefix: AccountType.Lk });
                  }
                }

                if (i == 'dasoToken') {
                  if (_response[i]) {
                    let dasoType: UrlInfo = { prefix: AccountType.Daso, id: type.id };

                    this.getService(dasoType).setToken(_response[i], dasoType);
                  }
                }
              }
            } else if (_response?.data) {
              this.setRefreshing(type, false);
              this.getService(type).setToken(_response?.data.token, type);
            } else if (_response?.access_token) {
              this.setRefreshing(type, false);
              this.getService(type).setToken(_response?.access_token, type);
            } else {
              this.setRefreshing(type, false);
              this.getService(type).setToken(response.headers.get('authorization'), type);
            }

            return next.handle(
              request.clone({
                setHeaders: {
                  Authorization: this.getService(type).getAuthHeader(type),
                },
              }),
            );
          }),
          catchError(error => {
            this.setRefreshing(type, false);

            if (error instanceof HttpErrorResponse) {
              switch ((error as HttpErrorResponse).status) {
                case 400:
                case 401:
                  return this.logout(error, type);
              }
            }
          }),
        );
    } else {
      return next.handle(request);
    }
  }

  private isAuthenticated(type: UrlInfo) {
    if (type.prefix === AccountType.Doorphone) {
      return this.getService(type).isAuthenticated({ prefix: AccountType.Main });
    }

    return this.getService(type).isAuthenticated(type);
  }

  isRefreshing(type: UrlInfo) {
    const key = `${type.prefix}-${type.id}`;

    if (!(key in this._isRefreshing)) {
      this._isRefreshing[key] = false;
    }

    return this._isRefreshing[key];
  }

  setRefreshing(type: UrlInfo, value: boolean) {
    this._isRefreshing[`${type.prefix}-${type.id}`] = value;
  }

  private needRefresh(type: UrlInfo) {
    if (type.prefix === AccountType.Doorphone) {
      return this.getService(type).getToken({ prefix: AccountType.Main });
    }

    if (type.prefix === AccountType.DasoIviz && this.isAuthenticated(type)) {
      return true;
    }

    return this.getService(type).getToken(type);
  }

  private getService(type: UrlInfo) {
    if (type.prefix === AccountType.Daso || type.prefix === AccountType.DasoIviz) {
      return this.dasoAuthService;
    } else {
      return this.authService;
    }
  }

  private parseUrl(url: string): UrlInfo {
    let matches = url.match(/([\w-]+)-(\d+):\/\//);

    if (matches) {
      return {
        prefix: matches[1] as AccountType,
        id: parseInt(matches[2], 10),
      };
    }

    matches = url.match(/([\w-]+):\/\//);

    if (matches) {
      return {
        prefix: matches[1].replace(/\d+/, '') as AccountType,
        id: 0,
      };
    }

    return null;
  }

  private getPlatform() {
    if (this.platform.is('ios')) {
      return 'iOS';
    } else if (this.platform.is('android')) {
      return 'Android';
    } else {
      return 'Web';
    }
  }
}
