import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterceptorPipe } from './interceptor.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        InterceptorPipe
    ],
    declarations: [
        InterceptorPipe
    ]
})
export class PipeModule { }
