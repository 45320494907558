export enum StorageKey {
  MiuiSettings = 'MIUI_SETTINGS',
  DasoToken = 'daso.tokens',
  DasoItem = 'daso.items',
  DasoFavorites = 'daso.favorites',
  IntercomSettings = 'intercom_settings',
  IvizCredentials = 'iviz_credentials',
  CityKey = 'city',
  IntercomBanner = 'doorphone_banner',
  EntryDevicesKey = 'contacts',
  IntercomUrls = 'open_urls',
  IntercomStatus = 'intercom_status',
  IntercomPending = 'pending_intercom',
  OffersData = 'data.offers',
  RequestNotification = 'notifications.requests',
  SipSettings = 'settings.sip',
  IntercomCamera = 'doorphoneCamera_',
  ReadNewsId = 'list_read_news_id',
  ChatNewMessages = 'chat_new_messages',
  ChatClientName = 'chat_client_name',
  DoorphoneRings = 'DOORPHONE_RINGS',
  EntityCountIviz = 'available_entity_count_iviz',
  AvailableEntityId = 'available_entity_id',
  IntercomCount = 'intercom_count',
  AvailableIntercomId = 'intercom_id',
  AvailableIntercomIds = 'intercom_ids',
  EntityCountDasoIviz = 'available_entity_count_daso_iviz',
  AvailableEntityIdDasoIviz = 'available_entity_id_daso',
  FavoritesCamera = 'favorites_camera',
  UserRoleInMyCamerasSection = 'user_role',
  DoorphoneUnlockCount = "doorphone_unlocks_count",
  LkReturnsCount = "lk_returns_count",
  ReviewDialogDate = "review_dialog_date",
  ReviewDialogState = "review_dialog_state",
  IdsToSortList = 'ids_to_sort',
  DoorphoneApiDomain = 'doorphone_api_domain',
  DeepLinkParams = 'dl_params',
}
