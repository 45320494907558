import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

@Injectable()
export class HttpApiUrlInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (request.url.substring(0, 7) == 'iviz://') {
        let nextRequest = request.clone({
            url: request.url.replace('iviz:/', environment.iviz.apiUrl)
        });

        return next.handle(nextRequest);
      }

      if (request.url.substring(0, 5) == 'lk://') {
        let nextRequest = request.clone({
            url: request.url.replace('lk:/', environment.lk.apiUrl)
        });

        return next.handle(nextRequest);
      }

      if (request.url.substring(0, 6) == 'lk2://') {
        let nextRequest = request.clone({
            url: request.url.replace('lk2:/', environment.lk.api2Url)
        });

        return next.handle(nextRequest);
      }

      if (request.url.substring(0, 7) == 'main://') {
        let nextRequest = request.clone({
            url: request.url.replace('main:/', environment.lk.apiUrl)
        });

        return next.handle(nextRequest);
      }

      if (request.url.substring(0, 8) == 'main2://') {
        let nextRequest = request.clone({
            url: request.url.replace('main2:/', environment.lk.api2Url)
        });

        return next.handle(nextRequest);
      }

      if (request.url.substring(0, 12) == 'doorphone://') {
        let nextRequest = request.clone({
            url: request.url.replace('doorphone:/', environment.doorphone.apiUrl)
        });

        return next.handle(nextRequest);
      }

      if (request.url.match(/daso-[0-9]+:\/\//)) {
        let nextRequest = request.clone({
            url: request.url.replace(/daso-[0-9]+:\//, environment.daso.apiUrl)
        });

        return next.handle(nextRequest);
      }

      if (request.url.match(/daso:\/\//)) {
        let nextRequest = request.clone({
            url: request.url.replace(/daso:\//, environment.daso.apiUrl)
        });

        return next.handle(nextRequest);
      }

      if (request.url.match(/daso-iviz-[0-9]+:\/\//)) {
        let nextRequest = request.clone({
            url: request.url.replace(/daso-iviz-[0-9]+:\//, environment.iviz.apiUrl)
        });

        return next.handle(nextRequest);
      }

      return next.handle(request);
    }
}
