import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../services/storage.service';
import { StorageKey } from 'src/app/enums/storage-key.enum';
import { DasoFilters } from 'src/app/_models/DasoFiltersInterface';

@Injectable({
  providedIn: 'root',
})
export class DasoService {
  private favorites: Array<number> = [];

  constructor(private http: HttpClient, private storage: StorageService) {}

  public find(page: number, filters: DasoFilters) {
    filters.favorites = this.getFavoriteIds();

    return this.http.post('daso://daso', {
      page,
      filters,
    });
  }

  public getFavorites() {
    const filters: DasoFilters = {
      favorites: this.getFavoriteIds(),
    };

    return this.http.post('daso://favorites', {
      filters,
    });
  }

  public getFavoriteIds() {
    return this.favorites;
  }

  public isFavorite(id: number) {
    return this.favorites.indexOf(id) === -1 ? false : true;
  }

  public setFavorite(id: number, flag: boolean) {
    const index = this.favorites.indexOf(id);

    if (flag) {
      if (index === -1) {
        this.favorites.push(id);
      }
    } else {
      if (index !== -1) {
        this.favorites = this.favorites.slice(0, index).concat(this.favorites.slice(index + 1));
      }
    }

    this.updateFavorites();
  }

  public loadFavorites() {
    this.storage.get(StorageKey.DasoFavorites).then((data: Array<number>) => {
      this.favorites = data != null ? data : [];
    });
  }

  private updateFavorites() {
    this.storage.set(StorageKey.DasoFavorites, this.favorites);
  }
}
