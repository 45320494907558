import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, NavigationStart, RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Network } from '@awesome-cordova-plugins/network/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CamerasService } from './services/cameras.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthHeaderInterceptor } from './interceptors/auth-header.interceptor';
import { HttpApiUrlInterceptor } from './interceptors/http-api-url.interceptor';

import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { DeviceService } from './services/device.service';
import { PipeModule } from './pipes/pipe.module';
import { filter } from 'rxjs/operators';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
// import { SecureStorage } from '@ionic-native/secure-storage/ngx';
import { StorageService } from './services/storage.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { StatusBar } from '@capacitor/status-bar';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { SwipeEvents } from '../app/components/swipe-events';

import * as Sentry from "@sentry/angular";

// https://www.freecodecamp.org/news/how-to-get-push-notifications-working-with-ionic-4-and-firebase-ad87cc92394e/
// https://stackoverflow.com/a/56028118
const firebaseConfig = {
  apiKey: 'AIzaSyAhriKRDsuZ4t5aqiWKZPpZzvN51yB7KWw',
  authDomain: 'evo-life.firebaseapp.com',
  databaseURL: 'https://evo-life.firebaseio.com',
  projectId: 'evo-life',
  storageBucket: 'evo-life.appspot.com',
  messagingSenderId: '924566543835',
  appId: '1:924566543835:web:d8201d4ec4ac6ee57f9bfe',
  measurementId: 'G-NM0EYVS8NT',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicStorageModule.forRoot({
      name: '__ru.simdev.evo.video',
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage], // CordovaSQLiteDriver._driver, Drivers.SecureStorage,
    }),
    PipeModule,
  ],
  providers: [
    Network,
    CamerasService,
    Clipboard,
    DeviceService,
    ScreenOrientation,
    StorageService,
    AppVersion,
    SwipeEvents,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpApiUrlInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      const tabBar = document.getElementById('navTabs');
      if (tabBar !== null && tabBar.style.display !== 'flex') {
        tabBar.style.display = 'flex';
      }
      StatusBar.show();
    });
  }
}
