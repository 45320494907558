import { Injectable } from '@angular/core';
import { CanActivate } from "@angular/router";
import { DoorphoneService } from "../services/doorphone.service";
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IsPaidGuard implements CanActivate {
  constructor(
    private doorphoneService: DoorphoneService,
    private route: ActivatedRoute,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let id = parseInt(route.paramMap.get('id'));

    return this.doorphoneService.isPaid(id);
  }
}
