import { registerPlugin } from '@capacitor/core';

export interface LivetexPlugAnswer {
  answer: string,
};

export type LivetexPluginErrorCallback = (error: string | null) => void;

export type LivetexPlugiAnswerCallback = (
  data: LivetexPlugAnswer | null,
  error?: any,
) => void;

export interface LivetexPlugin {
  initCore(): Promise<void>;
  open(options: { name: string }, callback?: LivetexPluginErrorCallback): Promise<void>;
  destroy(): Promise<void>;
  setCallback(options: any, callback: LivetexPlugiAnswerCallback): Promise<void>;
}

const Livetex = registerPlugin<LivetexPlugin>('Livetex');

export default Livetex;